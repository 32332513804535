import axios from "axios";
import { API_PREFIX } from "./constant";
//import {getToken} from './token';
//import {API_PREFIX } from './config';
import { getCookie } from "./cookies";
axios.defaults.headers.common["Cache-Control"] = "no-cache";
axios.defaults.headers.common.Pragma = "no-cache";
axios.defaults.headers.common.Expires = -1;
axios.defaults.headers.common["Content-Type"] =
  "application/json; charset=utf-8";
//axios.defaults.headers.common['content-type'] = 'multipart/form-data';
axios.defaults.headers.common.Accept = "application/json";

const axiosHandler = axios.create({
  baseURL: API_PREFIX + "api", // our API base URL
});

axiosHandler.interceptors.request.use(
  async (config) => {
    const token = await getCookie("token");
    if (token) {
      config.headers["X-Auth-Token"] = token;    
    } else {
      const tokenFromLocal = localStorage.getItem("token");
      if (tokenFromLocal) {
        config.headers["X-Auth-Token"] = tokenFromLocal;
      }
    }  
    config.headers["x-is-admin"] = true; 
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
axiosHandler.interceptors.response.use(
  (response) => {
    //const url = response.config.url;
    //setLocalStorageToken(token);
    return response;
  },
  (error) => {
    // if (error.response.status === 401) {
    //(`unauthorized :)`);
    //localStorage.removeItem("persist:root");
    //removeLocalStorageToken
    //window.location.href = "/login";
    //  }
    console.log("error", error);
    return Promise.reject(error);
  }
);

//axiosHandler.interceptors.response

export default axiosHandler;
