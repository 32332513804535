import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

const Home = lazy(() => import("../components/home/home"));
const NotFound = lazy(() => import("../components/notFound/notFound"));
const Login = lazy(() => import("../components/user/login"));
const AdminHome = lazy(() => import("../components/admin/adminHome/adminHome"));
const UserDetails = lazy(() =>
  import("../components/admin/userList/userDetails")
);
const AddAdminUser = lazy(() =>
  import("../components/admin/adminUser/addAdminUser")
);
const AdminUser = lazy(() => import("../components/admin/adminUser/adminUser"));
const CategoryList = lazy(() =>
  import("../components/admin/category/categoryList")
);
const AddCategory = lazy(() =>
  import("../components/admin/category/addCategory")
);
const AddSubcategory = lazy(() =>
  import("../components/admin/category/addSubCategory")
);
const DefaultImage = lazy(() =>
  import("../components/admin/defaultImage/defaultImage")
);
const UserList = lazy(() => import("../components/admin/userList/userList"));
const ItemList = lazy(() => import("../components/admin/itemList/itemList"));
const ChangePassword = lazy(() =>
  import("../components/admin/changePassword/changePassword")
);
const addConstant = lazy(() =>
  import("../components/admin/constantNew/constantNew")
);
const constantList = lazy(() =>
  import("../components/admin/constantNew/constantList")
);
const TermAndConditions = lazy(() =>
  import("../components/termAndConditions/termAndConditions")
);
const PrivacyPolicy = lazy(() =>
  import("../components/termAndConditions/privacyPolicy")
);
const ContactUs = lazy(() =>
  import("../components/admin/helpAndSupport/contactUs")
);
const HelpAndSupport = lazy(() =>
  import("../components/admin/helpAndSupport/helpAndSupport")
);
const DeleteUserAccount = lazy(() =>
  import("../components/deleteUserAccount/index")
);

const AppRouter = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/termAndConditions" element={<TermAndConditions />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route
          path="/deleteUserAccount"
          element={<DeleteUserAccount />}
        />
        <Route
          path="/userDetails/:userId"
          element={<PrivateRoute element={UserDetails} />}
        />
        <Route
          path="/adminHome"
          element={<PrivateRoute element={AdminHome} />}
        />
        <Route
          path="/addUser"
          element={<PrivateRoute element={AddAdminUser} />}
        />
        <Route
          path="/adminUser"
          element={<PrivateRoute element={AdminUser} />}
        />
        <Route
          path="/categoryList"
          element={<PrivateRoute element={CategoryList} />}
        />
        <Route
          path="/addCategory"
          element={<PrivateRoute element={AddCategory} />}
        />
        <Route
          path="/addSubCategory"
          element={<PrivateRoute element={AddSubcategory} />}
        />
        <Route
          path="/defaultImage"
          element={<PrivateRoute element={DefaultImage} />}
        />
        <Route path="/userList" element={<PrivateRoute element={UserList} />} />
        <Route path="/itemList" element={<PrivateRoute element={ItemList} />} />
        <Route
          path="/itemList/:userID"
          element={<PrivateRoute element={ItemList} />}
        />
        <Route
          path="/changePassword"
          element={<PrivateRoute element={ChangePassword} />}
        />
        <Route
          path="/addConstant"
          element={<PrivateRoute element={addConstant} />}
        />
        <Route
          path="/constantList"
          element={<PrivateRoute element={constantList} />}
        />
        <Route
          path="/contactUs"
          element={<PrivateRoute element={ContactUs} />}
        />
        <Route
          path="/helpAndSupport"
          element={<PrivateRoute element={HelpAndSupport} />}
        />
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
